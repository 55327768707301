import React from "react"
import { graphql } from "gatsby"
import PageLayout from "../components/layouts/layout-page"
import Search from "../components/search/App"
export const query = graphql`
  query SearchPageQuery {
    allSearchConfigYaml {
      nodes {
        productId
        algolia {
          apiKey
          appId
          indexName
          placeholderText
        }
      }
    }
  }
`

const SearchPage = ({ data, location }) => {
  const searchConfig = data.allSearchConfigYaml
  const pageInfo = {
    title: "Elastic Path Documentation - Search page",
    description:
      "Review search results or start a new search. Search results reflect the selected product only.",
    url: "https://documentation.elasticpath.com/search/",
  }

  return (
    <PageLayout pageInfo={pageInfo}>
      <section className="search">
        <Search searchConfig={searchConfig} location={location} />
      </section>
    </PageLayout>
  )
}

export default SearchPage
