import React from "react";
import GlobalLayout from "./layout-global.js";
import layoutStyle from "./layout-page.module.css";

export default ({ children, pageInfo }) => (

  <GlobalLayout pageInfo={pageInfo} >
    <div className={layoutStyle.contents}>
      {children}
    </div>
  </GlobalLayout>
)
